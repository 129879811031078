import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic11 from '../assets/images/pic11.jpg'

const Sustainable = (props) => (
  <Layout>
    <Helmet>
      <title>Sustainable</title>
      <meta name="description" content="Sustainable Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Sustainable</h1>
          </header>
          <span className="image main"><img src={pic11} alt="" /></span>
          <p>Sustainability is a complex concept. The most often quoted definition comes from the UN World Commission on Environment and Development: “sustainable development is development that meets the needs of the present without compromising the ability of future generations to meet their own needs.”</p>
          <p>As engineers and designers and parents with children, we are deeply concerned with our collective future.  We recognize the importance of ensuring future generations are able to enjoy the same or higher quality existence that we have enjoyed.  Our strategy for ‘renewable’ and ‘sustainable’ practices involves 4 aspects: Materials, Design and Durabilty.</p>
          <p>Ceramics are made from natural materials that are sustainable in all manufacturing stages – from material extraction to production and packaging, through to processing. They are stable, resistant to high temperatures and do not expand or shrink with temperature variations.  Ceramics are composed of a natural raw materials called clays which are extracted locally with the greatest possible consideration for the environment and the biodiversity of flora and fauna. The clay is processed, shaped and fired.  As a natural building material, ceramic products can be reused in many ways: including reuse for a new purpose or grinding and in brick production, road construction, as tennis court surface or even as soil conditioners in agriculture.</p>
          <p>Our designs take great care to use only what is needed so that materials, disposal and fuel use are kept to a minimum.  And, we engineer them to be tough so that they can provide many years of use without contributing to our landfills.</p>
        </div>
      </section>
    </div>

  </Layout>
)

export default Sustainable